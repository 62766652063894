input {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
    box-shadow: none !important;
    padding-left: 0;
}

input:focus {
    border-color: #dfca08;
    outline: none;
}

textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
    box-shadow: none !important;
    padding-left: 0;
    color: #fff;
}

textarea:focus {
    border-color: #dfca08;
    outline: none;
}